import { useQuery } from '@apollo/client';
import {
  getAvatarColor,
  NavigationDto,
  usePanel,
  useTheme,
} from '@ecdlink/core';
import { GetAllNavigation, GetTenantContext } from '@ecdlink/graphql';
import { Avatar, Button, Typography, UserAvatar } from '@ecdlink/ui';
import { Dialog, Menu, Transition } from '@headlessui/react';
import {
  ArrowLeftIcon,
  InformationCircleIcon,
  MenuAlt2Icon,
  XIcon,
} from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { AuthRoutes } from '../../app.routes';
import Icon from '../../components/icon';
import InformationPanel from '../../components/information-panel/information-panel';
import { useAuth } from '../../hooks/useAuth';
import { useUser } from '../../hooks/useUser';
import ggLogo from '../../../assets/gg-logo.svg';
import logo from '../../../assets/Logo-ECDConnect-white.svg';

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

type menuItemProps = {
  item: NavigationDto;
};
const MenuItem: React.FC<menuItemProps> = ({ item }) => {
  const routeMatch = useRouteMatch(item.route);

  if (routeMatch) {
    return (
      <div
        className={classNames(
          'bg-tertiary text-white',
          'group my-2 mx-1 flex items-center rounded-md px-2 py-2 text-sm font-medium text-white'
        )}
      >
        <Icon
          icon={item.icon}
          className={classNames('text-white', 'mr-3 h-6 w-6 flex-shrink-0')}
          color="transparent"
        />
        {item.name}
      </div>
    );
  }

  return (
    <Link
      to={item.route}
      className={classNames(
        'hover:bg-white hover:text-black',
        'group my-2 mx-1 flex items-center rounded-md px-2 py-2 text-sm font-medium text-white'
      )}
    >
      <Icon
        icon={item.icon}
        className={classNames(
          'text-white group-hover:text-gray-500',
          'mr-3 h-6 w-6 flex-shrink-0'
        )}
        color="transparent"
      />
      {item.name}
    </Link>
  );
};

export default function Shell() {
  const { theme } = useTheme();
  const panel = usePanel();
  const { logout } = useAuth();
  const { user } = useUser();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [avatarColor, setAvatarColor] = useState<string>();
  const [navigation, setNavigation] = useState<NavigationDto[]>();
  const [activeNavigation, setActiveNavigation] = useState<NavigationDto>();

  const { data: navigationData } = useQuery(GetAllNavigation, {
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (navigation && location && location.pathname) {
      const current = navigation.find((x) =>
        location.pathname.includes(x.route)
      );
      if (current) setActiveNavigation(current);
    }
  }, [navigation, location]);

  useEffect(() => {
    setAvatarColor(getAvatarColor());
  }, []);

  useEffect(() => {
    if (navigationData?.GetAllNavigation) {
      const navigationList: NavigationDto[] = navigationData.GetAllNavigation;
      const userRolePermissions = user.roles.map((x) => x.permissions).flat();
      const userPermissionIds = userRolePermissions.map((x) => x.id);
      if (
        user.roles.some((x) => x.name === 'Administrator') ||
        user.roles.some((x) => x.name === 'Super Admin')
      ) {
        const sorted = navigationList
          .slice()
          .sort((a, b) => a.sequence - b.sequence);
        setNavigation(sorted);
      } else {
        const filtered = navigationList.filter((x) =>
          x.permissions.some((z) => userPermissionIds.includes(z.id))
        );
        setNavigation(filtered.slice().sort((a, b) => a.sequence - b.sequence));
      }
    }
  }, [user, navigationData]);

  const { data } = useQuery(GetTenantContext, {
    fetchPolicy: 'cache-and-network',
  });

  const getLogoUrl = () => {
    if (
      theme &&
      theme.images &&
      data?.tenantContext.applicationName !== 'GrowGreat'
    ) {
      return theme.images.logoUrl;
    } else {
      return ggLogo;
    }
  };

  const signOutClick = () => {
    logout();
    history.push('/');
  };

  const gotToProfile = () => {
    history.push('/profile');
  };

  const displayInformationPanel = () => {
    panel({
      noPadding: true,
      title: 'Information',
      render: (onSubmit: any) => (
        <InformationPanel siteInformation={activeNavigation} />
      ),
    });
  };

  return (
    <div className="flex h-full overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 z-40 flex md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="darkBackground relative flex w-full max-w-xs flex-1 flex-col pt-5 pb-4">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="focus:outline-none ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-shrink-0 flex-col items-center justify-center px-4">
                <img className="h-100 mb-8" src={logo} alt="Login Logo" />

                <img
                  className="h-16 w-8/12"
                  src={getLogoUrl()}
                  alt="Workflow"
                />
              </div>
              <div className="mt-5 h-0 flex-1 overflow-y-auto">
                <nav className="space-y-1 px-2">
                  {navigation?.map((item) => (
                    <MenuItem
                      key={`${item.name}-${new Date().getTime()}`}
                      item={item}
                    ></MenuItem>
                  ))}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="w-14 flex-shrink-0" aria-hidden="true"></div>
        </Dialog>
      </Transition.Root>

      <div className="darkBackground hidden md:flex md:flex-shrink-0">
        <div className="flex w-64 flex-col">
          <div className="flex flex-grow flex-col overflow-y-auto pt-5 pb-4">
            <div className="flex flex-shrink-0 flex-col items-center justify-center px-4">
              <div></div>
              <img className="h-100 mb-8" src={logo} alt="Login Logo" />

              <img className="h-16 w-8/12" src={getLogoUrl()} alt="Workflow" />
            </div>
            <div className="mt-5 flex flex-1 flex-col">
              <nav className="flex-1 space-y-1 px-2">
                {navigation?.map((item) => (
                  <div>
                    <MenuItem
                      key={`${item.name}-${new Date().getTime()}`}
                      item={item}
                    ></MenuItem>

                    <hr className=" border-b-uiLight mx-2 border-dashed" />
                  </div>
                ))}
              </nav>
              <div className="mb-2 flex flex-col px-4 md:py-4">
                <Button
                  className={
                    'hover:bg-secondary mb-2 w-full rounded-xl hover:text-white'
                  }
                  type="filled"
                  // isLoading={isLoading}
                  color="uiMid"
                  // disabled={!isValid}
                  // onClick={signIn}
                  icon="InformationCircleIcon"
                >
                  <Typography
                    type="body"
                    color="white"
                    text={'Help'}
                    fontSize={'24'}
                  ></Typography>
                </Button>
                <Button
                  className={
                    'hover:bg-secondary w-full justify-self-start rounded-xl hover:text-white '
                  }
                  type="filled"
                  color="uiMid"
                  onClick={signOutClick}
                  icon="ArrowLeftIcon"
                >
                  <Typography
                    type="body"
                    color="white"
                    text={'Logout'}
                    fontSize={'24'}
                  ></Typography>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-0 flex-1 flex-col overflow-hidden">
        <div className="relative z-10 flex h-16 flex-shrink-0 bg-white shadow">
          <button
            type="button"
            className="focus:outline-none border-r border-gray-200 px-4 text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex flex-1 items-center justify-between px-4">
            <div className="flex flex-1 cursor-pointer pl-4">
              {activeNavigation && (
                <InformationCircleIcon
                  onClick={() => displayInformationPanel()}
                  className="text-infoMain ml-2"
                  height="25"
                  width="25"
                />
              )}

              <span className="pl-2 font-semibold text-black">
                {activeNavigation?.name}
              </span>
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              <Menu as="div" className="relative ml-3">
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button
                        onClick={gotToProfile}
                        className="focus:outline-none flex max-w-xs items-center rounded-full bg-white text-sm focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">Open user menu</span>

                        {user?.profileImageUrl ? (
                          <Avatar
                            size={'md'}
                            displayBorder
                            dataUrl={`${user?.profileImageUrl}`}
                            borderColor="secondary"
                          />
                        ) : (
                          <UserAvatar
                            size={'md'}
                            avatarColor={avatarColor}
                            text={`${user?.firstName[0]}${user?.surname[0]}`}
                            displayBorder
                          />
                        )}
                      </Menu.Button>
                    </div>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>

        <main className="focus:outline-none relative flex-1 overflow-y-auto bg-white">
          <div className="h-full py-6">
            <div className="mx-auto h-full bg-white px-4 sm:px-6 md:px-8">
              <AuthRoutes />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
