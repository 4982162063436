import {
  Config,
  initialResetPasswordValues,
  PasswordResetModel,
  resetPasswordSchema,
  useTheme,
} from '@ecdlink/core';
import { Button, Typography } from '@ecdlink/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import FormField from '../../form-field/form-field';
import logo from '../../../../assets/Logo-ECDConnect.svg';
import thumbs_up from '../../../../assets/icon_thumbsup.svg';
import zxcvbn from 'zxcvbn-typescript';
import { PasswordInput } from '../../password-input/password-input';

interface RouteParams {
  resetToken: string;
  email: string;
}

export default function ResetPassword() {
  const { theme } = useTheme();
  const [resetLinkSent, setResetLinkSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { resetPassword } = useAuth();
  const { resetToken } = useParams<RouteParams>();
  const { email } = useParams<RouteParams>();
  const [displayError, setDisplayError] = useState(false);

  const history = useHistory();

  const { register, getValues, formState, watch } = useForm({
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: initialResetPasswordValues,
    mode: 'onChange',
  });

  //check password strength
  const password = watch('password');
  const formValues = getValues();

  const requestResetPasword = async () => {
    let _email = localStorage.getItem('email') ?? email;

    if (isValid) {
      console.log(Config.authApi);

      setIsLoading(true);
      const body: PasswordResetModel = {
        username: _email,
        password: formValues.password,
        resetToken: resetToken,
      };
      const isLinkSent = await resetPassword(body, Config.authApi);

      if (isLinkSent) {
        setIsLoading(false);
        history.push('/login');
      } else {
        setIsLoading(false);
        // setDisplayError(true);
      }

      setTimeout(() => {
        // setDisplayError(false);
      }, 5000);
    }
  };

  const { errors, isValid } = formState;
  console.log(isValid);

  const submitResetPassword = async () => {
    if (isValid) {
      requestResetPasword();
      setResetLinkSent(!resetLinkSent);
      setIsLoading(!isLoading);
    }
  };

  const getLogoUrl = () => {
    if (theme && theme.images) {
      return <img className="h-100 w-3/12" src={logo} alt="Login Logo" />;
    } else {
      return <div className="h-32 w-32">&nbsp;</div>;
    }
  };
  if (resetLinkSent) {
    return (
      <div className="darkBackground flex min-h-screen items-center justify-center">
        <div className="m-8 rounded-xl bg-white p-8 shadow lg:w-1/3">
          <div className="flex flex-shrink-0 items-center justify-center">
            {getLogoUrl()}
          </div>
          <div className="flex flex-shrink-0 items-center justify-center">
            <h2 className="font-h1 textLight mt-6 text-2xl">
              Password reset successful!
            </h2>
          </div>

          <div className="flex flex-shrink-0 items-center justify-center pt-8">
            <img className="h-100 w-4/8" src={thumbs_up} alt="Login Logo" />
          </div>
          <p className="mb-3 pt-2 text-center text-lg text-gray-700">
            Please log in again to use ECD Connect.
          </p>

          <div className="mt-8">
            <div className="mt-6">
              <div>
                <Button
                  className={'mt-3 w-full rounded-xl'}
                  type="outlined"
                  color="secondary"
                  onClick={() => history.push('/')}
                  icon="ArrowLeftIcon"
                  textColor="secondary"
                >
                  <Typography
                    type="help"
                    color="secondary"
                    text={'Back to Login'}
                  ></Typography>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="darkBackground flex min-h-screen items-center justify-center">
        <div className="rounded bg-white p-8 shadow sm:w-1/3">
          <div className="flex flex-shrink-0 items-center justify-center">
            {getLogoUrl()}
          </div>
          <div className="flex flex-shrink-0 items-center justify-center">
            <h2 className="font-h1 textLight mt-6 text-2xl">
              Enter new password
            </h2>
          </div>
          <p className="text-md mb-3 pt-2 text-center text-gray-700">
            Fill in your new password.
          </p>

          <div className="mt-8">
            <div className="mt-6">
              <form className="space-y-6">
                <div className="space-y-1">
                  <PasswordInput
                    label={'Password'}
                    nameProp={'password'}
                    sufficIconColor="black"
                    value={formValues.password}
                    register={register}
                    strengthMeterVisible={true}
                    className="mb-9 "
                  />
                </div>

                <div>
                  <Button
                    className={'mt-3 w-full rounded'}
                    type="filled"
                    isLoading={isLoading}
                    color="secondary"
                    disabled={!isValid}
                    onClick={submitResetPassword}
                  >
                    <Typography
                      type="help"
                      color="white"
                      text={'Reset password'}
                    ></Typography>
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
